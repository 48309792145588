import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Vconsole from "vconsole";

import Vant from "vant";
import "vant/lib/index.css";

import * as filters from "@/filters";
import "@/assets/css/global.styl";
import utils from "@/utils";

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

// 移动端调试
if (process.env.VUE_APP_ENV === "stg") {
  new Vconsole();
}

Vue.use(Vant);
Vue.use(utils);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
