import Vue from "vue";

export default {
  install() {
    // 获取路由参数
    Vue.prototype.getQueryVariable = (param) => {
      const reg = new RegExp("(^|&)" + param + "=([^&]*)(&|$)");
      const r =
        window.location.search.substr(1).match(reg) ||
        window.location.hash
          .substring(window.location.hash.search(/\?/) + 1)
          .match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
    };

    Vue.prototype.copyEvent = (val) => {
      return new Promise((resolve) => {
        const _input = document.createElement("input"); // 直接构建input
        _input.value = val; // 设置内容
        document.body.appendChild(_input); // 添加临时实例
        _input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(_input); // 删除临时实例
        resolve();
      });
    };
    Vue.prototype.bonusPermutation = (arr, num) => {
      var r = [];
      (function f(t, a, n) {
        if (n == 0) return r.push(t);
        for (var i = 0, l = a.length; i <= l - n; i++) {
          f(t.concat(a[i]), a.slice(i + 1), n - 1); //取a数组的第一个值放入到t中，同时a数组删除取出来的那个值，余下的值用来遍历
        }
      })([], arr, num);
      return r;
    };
    Vue.prototype.reverseArray = (arr) => {
      var newArray = []; //新建的空数组是为了保存翻转后的数组的

      for (var i = 0; i < arr.length; i++) {
        newArray.unshift(arr[i]); //利用unshift 将原数组重新插入newArray[]数组中
      }
      console.log(newArray);
      return newArray;
    };
  },
};
