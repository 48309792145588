import axios from "axios";

const API_ROOT = process.env.VUE_APP_API_ROOT;

const api = axios.create({
  baseURL: API_ROOT,
});

// 接口请求前
api.interceptors.request.use(function (request) {
  // 是否已授权，是否有token
  request.url =
    request.url + (request.url.includes("?") ? "&" : "?") + "t=" + +new Date();
  let token = localStorage.getItem("lta.token");
  if (token) {
    request.headers.common["Authorization"] = `Bearer ${token}`;
    return request;
  }
  return request;
});

// 接口响应后
api.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("lta.token");
      if (process.env.VUE_APP_ENV === "stg") {
        window.location.href =
          "http://120.26.141.102:9001/oauth2/authorize?app_id=mq05722d531dbb&redirect_uri=" +
          encodeURIComponent("http://120.26.141.102:8011/");
      } else {
        window.location.href =
          `${process.env.VUE_APP_AUTHORIZE_HOST}/oauth2/authorize?app_id=mq05722d531dbb&redirect_uri=` +
          encodeURIComponent(location.origin) +
          "&t=" +
          +new Date();
      }
    } else {
      return Promise.reject(error.response.data);
    }
  }
);

export default api;
