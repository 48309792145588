import api from "@/api";

const account = {
  login(data) {
    return api.post(`/account/token`, data);
  },
  get() {
    return api.get(`/account/information`);
  },
  getCurrentData() {
    return api.get(`/account/agent/statistic/current`);
  },
  listHistoryData(params) {
    return api.get(`/account/agent/statistic/history`, {
      params: params,
    });
  },
  listCustomer(params) {
    return api.get(`/account/customer`, {
      params: params,
    });
  },
  getShareUrl() {
    return api.get(`/account/agent/share-url`);
  },
};

export default account;
