import Vue from "vue";
import Vuex from "vuex";
import account from "@/api/account";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentData: {},
    historyData: {}
  },
  mutations: {
    setCurrentData(state, data) {
      state.currentData = data;
    },
    setHistoryData(state, data) {
      state.historyData = data;
    }
  },
  actions: {
    getHistoryData({ commit }, params) {
      return new Promise(resolve => {
        account
          .listHistoryData(params)
          .then(res => {
            commit("setHistoryData", res.results);
            resolve(res.results);
          })
          .catch(() => {});
      });
    },

    getCurrentData({ commit }) {
      return new Promise(resolve => {
        account
          .getCurrentData()
          .then(res => {
            commit("setCurrentData", res.results);
            resolve(res.results);
          })
          .catch(() => {});
      });
    }
  },
  modules: {}
});
