import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/home/Index.vue"),
  },
  {
    path: "/project",
    name: "project",
    component: () =>
      import(/* webpackChunkName: "customer" */ "@/views/project/List.vue"),
  },

  {
    path: "/project/sports/detail",
    name: "project-sports-detail",
    component: () =>
      import(
        /* webpackChunkName: "project-sports-detail" */ "@/views/project/SportsDetail.vue"
      ),
  },
  {
    path: "/project/bd/detail",
    name: "project-bd-detail",
    component: () =>
      import(
        /* webpackChunkName: "project-bd-detail" */ "@/views/project/BdDetail.vue"
      ),
  },
  {
    path: "/project/number/detail",
    name: "project-number-detail",
    component: () =>
      import(
        /* webpackChunkName: "project-sports-detail" */ "@/views/project/NumberDetail.vue"
      ),
  },
  // {
  //   path: "/project/gj/detail",
  //   name: "project-gj-detail",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "project-gj-detail" */ "@/views/project/GjDetail.vue"
  //     ),
  // },
  {
    path: "/customer",
    name: "customer",
    component: () =>
      import(/* webpackChunkName: "customer" */ "@/views/customer/Index.vue"),
  },
  {
    path: "/customer/detail",
    name: "customer-detail",
    component: () =>
      import(
        /* webpackChunkName: "customer-detail" */ "@/views/customer/Detail.vue"
      ),
  },
  {
    path: "/customer/summary",
    name: "customer-summary",
    component: () =>
      import(
        /* webpackChunkName: "customer-summary" */ "@/views/customer/Summary.vue"
      ),
  },
  {
    path: "/customer/record",
    name: "customer-record",
    component: () =>
      import(
        /* webpackChunkName: "customer-record" */ "@/views/customer/Record.vue"
      ),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
