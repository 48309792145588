import dayjs from "dayjs";

export const formatCustomerActive = (val) => {
  if (!val) {
    return "超15日未下单";
  }
  let lastActiveAt = dayjs(val);

  let d3 = dayjs().subtract(3, "day");
  let d15 = dayjs().subtract(15, "day");

  if (lastActiveAt.isAfter(d3)) {
    return "近3日有下单";
  } else if (lastActiveAt.isBefore(d3) && lastActiveAt.isAfter(d15)) {
    return "超3日未下单";
  } else if (lastActiveAt.isBefore(d15)) {
    return "超15日未下单";
  } else {
    return "近3日有下单";
  }
};

export const formatAccountBookType = (val) => {
  let dict = {
    1: "收款明细",
    2: "清账",
    3: "投注",
    4: "兑奖金额",
    5: "手工添加",
    6: "手工扣减",
    7: "撤单退款",
    8: "清账撤回",
    9: "错奖回撤",
    10: "纠正返奖",
    11: "店铺返点",
    12: "跟单佣金",
    13: "跟单店主佣金",
    14: "发单佣金",
    15: "运营添加",
    16: "运营扣减",
    17: "合买保底退款",
    18: "合买参与扣减",
    19: "合买佣金",
    20: "合买店主佣金",
    21: "退票扣佣",
  };
  return dict[val];
};

export const formatBdSfgg = (val) => {
  let dict = {
    0: "主负",
    3: "主胜",
  };
  return dict[val];
};

// import currencyjs from "currency.js";

export const formatDate = (val) => {
  if (val) {
    return dayjs(val).format("MM-DD");
  } else {
    return "--";
  }
};

export const formatTime = (val) => {
  if (val) {
    return dayjs(val).format("HH:mm");
  } else {
    return "--";
  }
};

export const formatDateTime = (val) => {
  if (val) {
    return dayjs(val).format("MM-DD HH:mm");
  } else {
    return "--";
  }
};

export const formatMoneyValue = (val) => {
  val = val || 0;
  return "￥" + val;
};

export const formatWeekday = (val) => {
  let dict = {
    1: "周一",
    2: "周二",
    3: "周三",
    4: "周四",
    5: "周五",
    6: "周六",
    0: "周日",
  };
  return dict[val];
};

export const formatNumberDate = (val) => {
  if (dayjs().isSame(val, "date")) {
    return "今天";
  } else {
    return dayjs(val).format("MM-DD");
  }
};

export const formatQxcBit = (val) => {
  let dict = {
    1: "第一位",
    2: "第二位",
    3: "第三位",
    4: "第四位",
    5: "第五位",
    6: "第六位",
    7: "第七位",
  };
  return dict[val];
};

export const formatDay = (val) => {
  if (val) {
    return dayjs(val).format("DD");
  } else {
    return "--";
  }
};

export const formatMonth = (val) => {
  if (val) {
    return dayjs(val).format("MM");
  } else {
    return "--";
  }
};

export const formatProjectState = (val) => {
  let dict = {
    "-1": "已撤单",
    0: "待出票",
    1: "待开奖",
    2: "未中奖",
    3: "未兑奖",
    4: "已兑奖",
    5: "奖金正在计算",
  };
  return dict[val];
};

export const formatProjectPayType = (val) => {
  let dict = {
    f_hhgg_f: "混合过关",
    f_spf_f: "胜平负",
    f_rqspf_f: "让球胜平负",
    f_bf_f: "比分",
    f_zjq_f: "总进球",
    f_bqspf_f: "半全场",
    b_hhgg_f: "混合过关",
    b_sf_f: "胜负",
    b_rfsf_f: "让分胜负",
    b_sfc_f: "胜分差",
    b_dxf_f: "大小分",
    spf: "胜平负",
    zjq: "总进球",
    bf: "比分",
    bqcspf: "半全场",
    sfgg: "胜负过关",
    sxds: "上下单双",
  };
  return dict[val];
};

export const formatProjectType = (val) => {
  let dict = {
    jczq: "竞彩足球",
    jclq: "竞彩篮球",
    bd: "北京单场",
    sfc: "胜负彩",
    sfc14: "足球14场",
    sfc9: "任选9场",
    dlt: "大乐透",
    pls: "排列3",
    plw: "排列5",
    qxc: "7星彩",
    ssq: "双色球",
    qlc: "七乐彩",
    sd: "福彩3D",
    klb: "快乐8",
    jcgj: "猜冠军",
    jcgyj: "猜冠亚军",
  };
  return dict[val];
};

export const formatProjectPassType = (val) => {
  let dict = {
    "1_1": "单关",
    "2_1": "2串1",
    "3_1": "3串1",
    "4_1": "4串1",
    "5_1": "5串1",
    "6_1": "6串1",
    "7_1": "7串1",
    "8_1": "8串1",
    "9_1": "9串1",
    "10_1": "10串1",
    "11_1": "11串1",
    "12_1": "12串1",
    "13_1": "13串1",
    "14_1": "14串1",
    "15_1": "15串1",
  };
  return dict[val];
};

export const formatSportsSpf = (val) => {
  let dict = {
    3: "胜",
    1: "平",
    0: "负",
  };
  return dict[val];
};

export const formatSportsRqspf = (val) => {
  let dict = {
    3: "让胜",
    1: "让平",
    0: "让负",
  };
  return dict[val];
};

export const formatSportsBf = (val) => {
  let dict = {
    10: "1:0",
    11: "1:1",
    12: "1:2",
    13: "1:3",
    14: "1:4",
    15: "1:5",
    20: "2:0",
    21: "2:1",
    22: "2:2",
    23: "2:3",
    24: "2:4",
    25: "2:5",
    30: "3:0",
    31: "3:1",
    32: "3:2",
    33: "3:3",
    40: "4:0",
    41: "4:1",
    42: "4:2",
    50: "5:0",
    51: "5:1",
    52: "5:2",
    90: "胜其他",
    99: "平其他",
    "00": "0:0",
    "01": "0:1",
    "02": "0:2",
    "03": "0:3",
    "04": "0:4",
    "05": "0:5",
    "09": "负其他",
  };
  return dict[val];
};

export const formatSportsZjq = (val) => {
  let dict = {
    0: "0球",
    1: "1球",
    2: "2球",
    3: "3球",
    4: "4球",
    5: "5球",
    6: "6球",
    7: "7+球",
  };
  return dict[val];
};

export const formatSportsBqc = (val) => {
  let dict = {
    "00": "负负",
    "01": "负平",
    "03": "负胜",
    10: "平负",
    11: "平平",
    13: "平胜",
    30: "胜负",
    31: "胜平",
    33: "胜胜",
  };
  return dict[val];
};

export const formatSportsRfsf = (val) => {
  let dict = {
    3: "让胜",
    0: "让负",
  };
  return dict[val];
};

export const formatSportsDxf = (val) => {
  let dict = {
    1: "大",
    0: "小",
  };
  return dict[val];
};

export const formatSportsSf = (val) => {
  let dict = {
    3: "胜",
    0: "负",
  };
  return dict[val];
};

export const formatSportsSfc = (val) => {
  let dict = {
    "01": "主胜1-5",
    "02": "主胜6-10",
    "03": "主胜11-15",
    "04": "主胜16-20",
    "05": "主胜21-25",
    "06": "主胜26+",
    "07": "客胜1-5",
    "08": "客胜6-10",
    "09": "客胜11-15",
    10: "客胜16-20",
    11: "客胜21-25",
    12: "客胜26+",
  };

  // let dict = {
  //   "01": "客胜1-5",
  //   "02": "客胜6-10",
  //   "03": "客胜11-15",
  //   "04": "客胜16-20",
  //   "05": "客胜21-25",
  //   "06": "客胜26+",
  //   "07": "主胜1-5",
  //   "08": "主胜6-10",
  //   "09": "主胜11-15",
  //   "10": "主胜16-20",
  //   "11": "主胜21-25",
  //   "12": "主胜26+"
  // };
  return dict[val];
};

export const formatBdSxds = (val) => {
  let dict = {
    1: "上单",
    2: "上双",
    3: "下单",
    4: "下双",
  };
  return dict[val];
};

export const formatGrouponState = (val) => {
  let dict = {
    "-1": "已失败",
    0: "进行中",
    1: "已成功",
    2: "未中奖",
    3: "已中奖",
    4: "已中奖",
  };
  return dict[val];
};

export const formatGrouponProjectState = (val) => {
  let dict = {
    "-1": "已撤单",
    0: "待出票",
    1: "待开奖",
    2: "未中奖",
    3: "未兑奖",
    4: "已兑奖",
    5: "奖金正在计算",
  };
  return dict[val];
};

export const formatStoreBookType = (val) => {
  let dict = {
    1: "充值加点",
    2: "服务点提现",
    3: "接单扣点",
    4: "平台加点",
    5: "跟单扣佣",
    6: "跟单返佣",
    7: "发单佣金",
    8: "平台扣点",
    9: "合买扣佣",
    10: "合买返佣",
  };
  return dict[val];
};

export const formatSettlementState = (val) => {
  let dict = {
    "-1": "已撤销",
    0: "待处理",
    1: "已处理",
  };
  return dict[val];
};

export const formatStorePoint = (val) => {
  return "剩余 " + val + " 点";
};

export const formatStoreState = (val) => {
  let dict = {
    "-3": "已关闭",
    "-2": "休息中",
    "-1": "未通过",
    0: "待审核",
    1: "已通过",
    2: "营业中",
  };
  return dict[val];
};

export const formatCertificateState = (val) => {
  if (val > 0 || val === -2) {
    return "已认证";
  } else {
    return "未认证";
  }
};

export const formatPayType = (val) => {
  let dict = {
    1: "微信",
    2: "支付宝",
    3: "银行卡",
    9: "到店处理",
  };
  return dict[val];
};

export const formatProjectPlayType = (val) => {
  let dict = {
    pls: {
      201: "直选",
      202: "组选单式",
      203: "组选三复式",
      204: "组选六复式",
      205: "直选组合复式",
      206: "直选和值",
      207: "组选和值",
      208: "直选组合胆拖",
      209: "组三胆拖",
      210: "组六胆拖",
    },
    plw: {
      101: "单式投注",
      102: "复式位选投注",
    },
    qxc: {
      101: "单式投注",
      102: "复式位选投注",
    },
    dlt: {
      101: "基本单式投注",
      102: "基本复式投注",
      103: "追加单式投注",
      104: "追加复式投注",
      106: "基本胆拖投注",
      107: "追加胆拖投注",
    },
    sfc: {
      101: "单式",
      102: "任九场单式",
      103: "复式",
      104: "任九场复式",
    },
    ssq: {
      101: "单式投注",
      102: "复式投注",
      103: "胆拖投注",
    },
    qlc: {
      101: "单式投注",
      102: "复式投注",
      103: "胆拖投注",
    },
    sd: {
      101: "直选单式",
      102: "直选复式",
      201: "组选单式",
      302: "组三复式",
      602: "组六复式",
    },
    klb: {
      1011: "选一单式",
      1012: "选一复式",
      1021: "选二单式",
      1022: "选二复式",
      1023: "选二胆拖",
      1031: "选三单式",
      1032: "选三复式",
      1033: "选三胆拖",
      1041: "选四单式",
      1042: "选四复式",
      1043: "选四胆拖",
      1051: "选五单式",
      1052: "选五复式",
      1053: "选五胆拖",
      1061: "选六单式",
      1062: "选六复式",
      1063: "选六胆拖",
      1071: "选七单式",
      1072: "选七复式",
      1073: "选七胆拖",
      1081: "选八单式",
      1082: "选八复式",
      1083: "选八胆拖",
      1091: "选九单式",
      1092: "选九复式",
      1093: "选九胆拖",
      1101: "选十单式",
      1102: "选十复式",
      1103: "选十胆拖",
    },
  };
  // let temp = "";
  // if (val[0] === "pls") {
  //   if (val[1] === "201") {
  //     if (
  //       val[2][0].length > 1 ||
  //       val[2][1].length > 1 ||
  //       val[2][2].length > 1
  //     ) {
  //       temp = "复式";
  //     } else {
  //       temp = "单式";
  //     }
  //   }

  //   if (val[1] === "203") {
  //     if (val[2][0].length === 2) {
  //       temp = "单式";
  //     } else {
  //       temp = "复式";
  //     }
  //   }

  //   if (val[1] === "204") {
  //     if (val[2][0].length === 3) {
  //       temp = "单式";
  //     } else {
  //       temp = "复式";
  //     }
  //   }
  // }
  return dict[val[0]][val[1]];
};

export const formatBetMultiTicketCount = (val) => {
  if (parseInt(val) > 50) {
    return parseInt(val / 50);
  }
  return 1;
};

export const formatCancelType = (val) => {
  let dict = {
    0: "未撤单", // 未撤单
    1: "客户撤单",
    2: "店主撤单",
    3: "运营撤单",
    4: "系统撤单",
    5: "平台撤单",
  };
  return dict[val];
};

// export const formatStatisticMoney = (val) => {
//   return currencyjs(val, { precision: 2, symbol: "￥" }).format();
// };
