<template>
  <div id="app">
    <KeepAlive
      :exclude="[
        'project-bd-detail',
        'project-sports-detail',
        'project-gj-detail',
        'project-number-detail',
        'customer-record',
        'customer-detail',
      ]"
    >
      <router-view />
    </KeepAlive>
  </div>
</template>

<script>
import account from "@/api/account";
import dayjs from "dayjs";

export default {
  name: "app",
  created() {
    let month = dayjs().format("YYYY-MM");
    if (process.env.NODE_ENV === "development") {
      localStorage.setItem("lta.token", process.env.VUE_APP_TEST_TOKEN);
      this.$store.dispatch("getAccountInformation");
      this.$store.dispatch("getCurrentData");
      this.$store.dispatch("getHistoryData", {
        month: month,
      });
      return;
    }
    let token = localStorage.getItem("lta.token");
    if (token) {
      this.$store.dispatch("getAccountInformation");
      this.$store.dispatch("getCurrentData");
      this.$store.dispatch("getHistoryData", {
        month: month,
      });
    } else {
      let code = this.getQueryVariable("code");
      if (this.getQueryVariable("link")) {
        localStorage.setItem("link", this.getQueryVariable("link"));
      }
      if (code) {
        let data = {
          code: code,
        };
        account
          .login(data)
          .then((res) => {
            localStorage.setItem("lta.token", res.results.token);

            this.$store.dispatch("getAccountInformation");
            this.$store.dispatch("getCurrentData");
            this.$store.dispatch("getHistoryData", {
              month: month,
            });
          })
          .catch((res) => {
            if (res.code === 401) {
              this.$router.push({
                name: "store-tip",
              });
            }
          });
      } else {
        if (process.env.VUE_APP_ENV === "stg") {
          window.location.href =
            "http://120.26.141.102:9001/oauth2/authorize?app_id=mq05722d531dbb&redirect_uri=" +
            encodeURIComponent("http://120.26.141.102:8011/");
        } else {
          window.location.href =
            `${process.env.VUE_APP_AUTHORIZE_HOST}/oauth2/authorize?app_id=mq05722d531dbb&redirect_uri=` +
            encodeURIComponent(location.origin) +
            "&t=" +
            +new Date();
        }
      }
    }
  },
};
</script>

<style lang="stylus">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  height: 100%;
}

body {
  height: 100%;
}
</style>
